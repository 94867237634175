import { Link } from 'react-router-dom'

function Policy() {
  return (
    <div className='container'>
      <div>
        <h1 style={{ textAlign: 'center' }}>
          <strong>PRIVACY POLICY</strong>
        </h1>

        <p>Effective date: 2021-01-01</p>

        <p>
          <h2>1. Introduction</h2>
        </p>

        <p>Welcome to Orbo AI</p>

        <p>
          <strong>A product of Modaviti e-Marketing Private Limited</strong>{' '}
          (“us”, “we”, or “our”) operates https://www.orbo.ai/,
          https://www.allscan.in and https://superenhance.ai (hereinafter
          referred to as “Service”).
        </p>

        <p>
          Our Privacy Policy governs your visit to{' '}
          <strong>
            Orbo.ai, allscan.in, superenhance.ai, supertouch.ai, superscan.ai
            and its subdomains
          </strong>{' '}
          and Supertouch mobile app and explains how we collect, safeguard and
          disclose information that results from your use of our Service.
        </p>
        <p>
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
        </p>

        <p>
          Our Terms and Conditions (“Terms”) govern all use of our Service and
          together with the Privacy Policy constitutes your agreement with us
          (“agreement”).
        </p>

        <p>
          <h2>2. Definitions</h2>
        </p>

        <p>
          <strong>SERVICE</strong> means the orbo.ai, allscan.in &amp;
          superenhance.ai website operated by Modaviti e-Marketing Private
          Limited.
        </p>

        <p>
          <strong>PERSONAL DATA</strong> means data about a living individual
          who can be identified from those data (or from those and other
          information either in our possession or likely to come into our
          possession).
        </p>

        <p>
          <strong>USAGE DATA</strong> is data collected automatically either
          generated by the use of Service or from Service infrastructure itself
          (for example, the duration of a page visit).
        </p>

        <p>
          <strong>COOKIES</strong> are small files stored on your device
          (computer or mobile device).
        </p>

        <p>
          <strong>DATA CONTROLLER</strong> means a natural or legal person who
          (either alone or jointly or in common with other persons) determines
          the purposes for which and the manner in which any personal data are,
          or are to be, processed. For the purpose of this Privacy Policy, we
          are a Data Controller of your data.
        </p>

        <p>
          <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> mean any
          natural or legal person who processes the data on behalf of the Data
          Controller. We may use the services of various Service Providers in
          order to process your data more effectively.
        </p>

        <p>
          <strong>DATA SUBJECT</strong> is any living individual who is the
          subject of Personal Data.
        </p>

        <p>
          <strong>THE USER</strong> is the individual using our Service. The
          User corresponds to the Data Subject, who is the subject of Personal
          Data.
        </p>

        <p>
          <h2>3. Information Collection and Use</h2>
        </p>

        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>

        <p>
          <strong>Types of Data Collected</strong>
        </p>

        <p>
          <strong>Personal Data</strong>
        </p>

        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (<strong>“Personal Data”</strong>). Personally,
          identifiable information may include, but is not limited to:
        </p>

        <p>0.1. Email address</p>
        <p>0.2. First name and last name</p>
        <p>0.3. Phone number</p>
        <p>0.4. Address, Country, State, Province, ZIP/Postal code, City</p>
        <p>0.5. Cookies and Usage Data</p>

        <p>
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link.
        </p>

        <p>
          <strong>Usage Data</strong>
        </p>

        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access Service by or through any device
          (<strong>“Usage Data”</strong>).
        </p>

        <p>
          This Usage Data may include information such as your computer’s
          Internet Protocol address (e.g., IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>

        <p>
          When you access Service with a device, this Usage Data may include
          information such as the type of device you use, your device unique ID,
          the IP address of your device, your device operating system, the type
          of Internet browser you use, unique device identifiers and other
          diagnostic data.
        </p>

        <p>
          <strong>Face Data in Supertouch Mobile App</strong>
        </p>

        <p>
          Neither your facial photo/video nor your facial feature vectors will
          be stored in our database in any form whatsoever. All facial effects,
          hair color, styling and skin analysis is done on your device and we do
          not store any information in our database. We do not transfer user's
          facial information from user's mobile app to our back-end servers.
        </p>

        <p>
          <strong>Location Data</strong>
        </p>

        <p>
          We may use and store information about your location if you give us
          permission to do so (<strong>“Location Data”</strong>). We use this
          data to provide features of our Service, to improve and customize our
          Service.
        </p>

        <p>
          You can enable or disable location services when you use our Service
          at any time by way of your device settings.
        </p>

        <p>
          <strong>Tracking Cookies Data</strong>
        </p>

        <p>
          We use cookies to improve and personalize its Sites and Services
          and/or measure its audience. Cookies are files saved to your local
          storage when browsing on the internet and in particular on our Sites.
          A cookie is not used to gather your personal data without your
          knowledge but instead to record information on site browsing which can
          be read directly by us on your subsequent visits.
        </p>

        <p>
          You can choose to decline acceptance of all cookies, but your ability
          to browse certain pages of our Sites may be reduced. The cookies used
          by us are intended to enable or facilitate communication, to enable
          the Services requested by users to be supplied, to recognize users
          when they re-visit the site, to secure payments which users may make,
          or other preferences necessary for the service requested to be
          supplied and to enable us to carry out analyses on hit rates and
          browsing experience so as to improve content, to track email open
          rates, click rates, and bounce-back rates at individual levels.
        </p>

        <p>
          By default, cookies are not installed automatically (except for those
          cookies needed to run our Sites and Services, and you are informed of
          their installation by a clickable banner with a text description). In
          accordance with the regulations that apply, we will require your
          authorization before implanting any other kind of cookie to your local
          storage. To avoid being bothered by these routine requests for
          authorization and to enjoy uninterrupted browsing, you can configure
          your device to accept our cookies, or we can remember your refusal or
          acceptance of certain cookies. By default, browsers accept all
          cookies.
        </p>

        <p>
          When you access third party sites on our Sites, or when you are
          reading integration or social media links, cookies can be created by
          the companies disseminating these links. These third parties may be
          able to use cookies in the context of ourServices (partners or other
          third parties supplying content or services available on the our site)
          and are responsible for the cookies they install, and it is their
          conditions on cookies which apply. We assume no liability regarding
          the possible use of cookies by third parties. For more information,
          you are advised to check the cookie policy directly on these
          third-party sites concerning their use of cookies.
        </p>

        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>

        <p>Examples of Cookies we use: </p>
        <p>
          {' '}
          0.1. <strong>Session Cookies:</strong> We use Session Cookies to
          operate our Service.
        </p>
        <p>
          0.2. <strong>Preference Cookies:</strong> We use Preference Cookies to
          remember your preferences and various settings.
        </p>
        <p>
          0.3. <strong>Security Cookies:</strong> We use Security Cookies for
          security purposes.
        </p>
        <p>
          0.4. <strong>Advertising Cookies:</strong> Advertising Cookies are
          used to serve you with advertisements that may be relevant to you and
          your interests.
        </p>

        <p>
          <strong>Use of Data</strong>
        </p>

        <p>
          Modaviti e-Marketing Private Limited uses the collected data for
          various purposes:
        </p>

        <p>0.1. to provide and maintain our Service;</p>
        <p>0.2. to notify you about changes to our Service;</p>
        <p>
          0.3. to allow you to participate in interactive features of our
          Service when you choose to do so;
        </p>
        <p>0.4. to provide customer support;</p>
        <p>
          0.5. to gather analysis or valuable information so that we can improve
          our Service;
        </p>
        <p>0.6. to monitor the usage of our Service;</p>
        <p>0.7. to detect, prevent and address technical issues;</p>
        <p>0.8. to fulfil any other purpose for which you provide it;</p>
        <p>
          0.9. to carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing
          and collection;
        </p>
        <p>
          0.10. to provide you with notices about your account and/or
          subscription, including expiration and renewal notices,
          email-instructions, etc.;
        </p>
        <p>
          0.11. to provide you with news, special offers and general information
          about other goods, services and events which we offer that are similar
          to those that you have already purchased or enquired about unless you
          have opted not to receive such information;
        </p>
        <p>
          0.12. in any other way we may describe when you provide the
          information;
        </p>
        <p>0.13. for any other purpose with your consent.</p>

        <p>
          <strong>Retention of Data</strong>
        </p>

        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </p>

        <p>
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period, except when this data
          is used to strengthen the security or to improve the functionality of
          our Service, or we are legally obligated to retain this data for
          longer time periods.
        </p>

        <p>
          <strong>Transfer of Data</strong>
        </p>

        <p>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>

        <p>
          If you are located outside India and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to
          India and process it there.
        </p>

        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>

        <p>
          Modaviti e-Marketing Private Limited will take all the steps
          reasonably necessary to ensure that your data is treated securely and
          in accordance with this Privacy Policy and no transfer of your
          Personal Data will take place to an organisation or a country unless
          there are adequate controls in place including the security of your
          data and other personal information.
        </p>

        <p>
          <strong>Disclosure of Data</strong>
        </p>

        <p>
          We may disclose personal information that we collect, or you provide:
        </p>

        <p>
          <strong>0.1. Disclosure for Law Enforcement.</strong>
        </p>

        <p>
          Under certain circumstances, we may be required to disclose your
          Personal Data if required to do so by law or in response to valid
          requests by public authorities.
        </p>

        <p>
          <strong>0.2. Business Transaction.</strong>
        </p>

        <p>
          If we or our subsidiaries are involved in a merger, acquisition or
          asset sale, your Personal Data may be transferred.
        </p>

        <p>
          <strong>
            0.3. Other cases. We may disclose your information also:
          </strong>
        </p>

        <p>0.3.1. to our subsidiaries and affiliates;</p>

        <p>
          0.3.2. to contractors, service providers, and other third parties we
          use to support our business; refer to clause no 17.
        </p>

        <p>0.3.3. to fulfil the purpose for which you provide it;</p>

        <p>
          0.3.4. for the purpose of including your company’s logo on our
          website;
        </p>

        <p>
          0.3.5. for any other purpose disclosed by us when you provide the
          information;
        </p>

        <p>0.3.6. with your consent in any other cases;</p>

        <p>
          0.3.7. if we believe disclosure is necessary or appropriate to protect
          the rights, property, or safety of the Company, our customers, or
          others.
        </p>

        <p>
          <strong>Security of Data</strong>
        </p>

        <p>
          Within the framework of its Services, we attribute the very highest
          importance to the security and integrity of information and personal
          data. We observe reasonable procedures to prevent unauthorized access
          to, and the misuse of, information including personal data. We use
          appropriate business systems and procedures to protect and safeguard
          information including personal data. We also use security and
          managerial procedures and industry standard technical restrictions for
          accessing and using the personal data on our servers. Only authorized
          personnel are permitted to access personal data in the course of their
          work.
        </p>

        <p>
          Thus and in accordance with the GDPR and CCPA, We undertake all
          pertinent precautions in order to preserve the security of the data
          and, in particular, to protect them against any accidental or unlawful
          destruction, accidental loss, corruption, unauthorized circulation or
          access, as well as against any other form of unlawful processing or
          disclosure to unauthorized persons.
        </p>

        <p>
          To this end, we implement industry standard security measures to
          protect personal data from unauthorized disclosure.
        </p>

        <p>
          The security of your data is important to us but no method of
          transmission over the Internet or method of electronic storage is 100%
          secure. While we strive to use commercially acceptable means to
          protect your Personal Data, we cannot guarantee its absolute security.
        </p>

        <p>
          <strong>
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR)
          </strong>
        </p>

        <p>
          If you are a resident of the European Union (EU) and European Economic
          Area (EEA), you have certain data protection rights, covered by GDPR.
        </p>

        <p>
          We aim to take reasonable steps to allow you to correct, amend,
          delete, or limit the use of your Personal Data.
        </p>

        <p>
          If you wish to be informed about Personal data, we hold information
          about you and if you want it to be removed from our systems, please
          email us at support@orbo.ai.
        </p>

        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>

        <p>
          0.1. the right to access, update or to delete the information we have
          on you;
        </p>

        <p>
          0.2. the right of rectification. You have the right to have your
          information rectified if that information is inaccurate or incomplete;
        </p>

        <p>
          0.3. the right to object. You have the right to object to our
          processing of your Personal Data;
        </p>

        <p>
          0.4. the right of restriction. You have the right to request that we
          restrict the processing of your personal information;
        </p>

        <p>
          0.5. the right to data portability. You have the right to be provided
          with a copy of your Personal Data in a structured, machine-readable
          and commonly used format;
        </p>

        <p>
          0.6. the right to withdraw consent. You also have the right to
          withdraw your consent at any time where we rely on your consent to
          process your personal information;
        </p>

        <p>
          Please note that we may ask you to verify your identity before
          responding to such requests. Please note, we may not able to provide
          Service without some necessary data.
        </p>

        <p>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </p>

        <p>
          <strong>Your Choice and Rights</strong>
        </p>

        <p>
          We want you to be in control of how your personal information is used
          by us. In accordance with the EU-U.S. and Swiss-U.S. Privacy Shield
          Frameworks, the and the California Consumer Privacy Act (CCPA), you
          can do this in the following ways:
        </p>

        <p>
          You can ask us for a copy of the personal information we hold about
          you.
        </p>

        <p>
          You have the right to know whether your personal information is sold
          or disclosed and to whom.
        </p>

        <p>
          You have the right to say no to the sale of your personal information.
        </p>

        <p>
          You can inform us of any changes to your personal information, or you
          can ask us to correct any of the personal information we hold about
          you. You are also able at any time to modify your personal information
          by accessing your account settings on our Sites.
        </p>

        <p>
          In certain situations, you can ask us to erase, block, or restrict the
          processing of the personal information we hold about you or object to
          particular ways in which we are using your personal information.
        </p>

        <p>
          In certain situations, you can also ask us to send the personal
          information you have given us to a third party.
        </p>

        <p>
          Where we are using your personal information on the basis of your
          consent, you are entitled to withdraw that consent at any time subject
          to applicable law. Moreover, where we process your personal
          information based on legitimate interest or the public interest, you
          have the right to object at any time to that use of your personal
          information subject to applicable law.
        </p>

        <p>
          We rely on you to ensure that your personal information is complete,
          accurate, and current. Please do inform us promptly of any changes to
          or inaccuracies of your personal information by contacting
          support@orbo.ai We may require your application to be accompanied by a
          photocopy of proof of identity or authority.
        </p>

        <p>
          Please note, you are entitled to ask us to provide you with
          information up to two times in a rolling twelve-month period. When you
          make this request, the information provided may be limited to the
          personal information we collected about you in the previous 12 months.
        </p>

        <p>
          <strong>
            0.1. To delete your personal information. If you make this request,
            we will delete the personal information we hold about you as of the
            date of your request from our records and direct any service
            providers to do the same. In some cases, deletion may be
            accomplished through de-identification of the information. If you
            choose to delete your personal information, you may not be able to
            use certain functions that require your personal information to
            operate.
          </strong>
        </p>

        <p>
          <strong>
            0.2. To stop selling your personal information. We don’t sell or
            rent your personal information to any third parties for any purpose.
            We do not sell your personal information for monetary consideration.
            You are the only owner of your Personal Data and can request
            disclosure or deletion at any time.
          </strong>
        </p>

        <p>
          Please note, if you ask us to delete your data, it may impact your
          experience with us, and you may not be able to participate in certain
          programs or membership services which require the usage of your
          personal information to function. But in no circumstances, we will
          discriminate against you for exercising your rights.
        </p>

        <p>
          <strong>Service Providers</strong>
        </p>

        <p>
          We may employ third party companies and individuals to facilitate our
          Service (<strong>“Service Providers”</strong>), provide Service on our
          behalf, perform Service-related services or assist us in analysing how
          our Service is used.
        </p>

        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>

        <p>
          <strong>Analytics</strong>
        </p>

        <p>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </p>

        <p>
          <strong>CI/CD tools</strong>
        </p>

        <p>
          We may use third-party Service Providers to automate the development
          process of our Service.
        </p>

        <p>
          <strong>Behavioural Remarketing</strong>
        </p>

        <p>
          We may use remarketing services to advertise on third party websites
          to you after you visited our Service. We and our third-party vendors
          use cookies to inform, optimise and serve ads based on your past
          visits to our Service.
        </p>

        <p>
          <strong>Payments</strong>
        </p>

        <p>
          We may provide paid products and/or services within Service. In that
          case, we use third-party services for payment processing (e.g.,
          payment processors).
        </p>

        <p>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>

        <p>
          <strong>Links to Other Sites</strong>
        </p>

        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third- party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>

        <p>
          <strong>Children’s Privacy</strong>
        </p>

        <p>
          Our Services are not intended for use by children under the age of 18
          (<strong>“Child”</strong> or
          <strong>“Children”</strong>).
        </p>

        <p>
          We do not knowingly collect personally identifiable information from
          Children under 18. If you become aware that a Child has provided us
          with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from Children without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>

        <p>
          <strong>Changes to This Privacy Policy</strong>
        </p>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <p>
          <strong>Contact Us</strong>
        </p>

        <p>
          If you have any questions about this Privacy Policy, please contact us
          by email:
          <strong>support@orbo.ai</strong>.
        </p>
      </div>
      <Link to='/'>back</Link>
    </div>
  )
}

export default Policy
