import { CssBaseline } from '@mui/material'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import useStyles from './styles'

import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { login as loginAction } from '../../features/authSlice'
import ActivateLicense from '../ActivateLicense/inedx'
import CancellationPolicy from '../CancellationPolicy'
import FindClient from '../FindClient'
import LicenseHistory from '../LicenseHistory'
import Policy from '../Policy'
import Terms from '../Terms'
import BoxPurchase from '../BoxPurchase'

const App = () => {
  const classes = useStyles()
  const authStatus = useSelector((state) => state.auth.status)
  const dispatch = useDispatch()

  useEffect(() => {
    checkUserLogin()
  }, [])

  const checkUserLogin = () => {
    if (sessionStorage['username']) {
      dispatch(loginAction())
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline>
        {/* {authStatus && <NavBar />} */}
        <main className={classes.content}>
          <div className={classes.toolbar}></div>
          <Routes>
            {/* <Route path='/' element={<Login />}></Route> */}
            <Route path="/" element={<FindClient />}></Route>
            <Route
              path="/activate-license"
              element={<ActivateLicense />}
            ></Route>
            <Route path="/box-purchase" element={<BoxPurchase />}></Route>
            <Route path="/license-history" element={<LicenseHistory />}></Route>
            <Route path="/terms-and-conditions" element={<Terms />}></Route>
            <Route path="/policy" element={<Policy />}></Route>
            <Route
              path="/cancellation-policy"
              element={<CancellationPolicy />}
            ></Route>
          </Routes>
        </main>
      </CssBaseline>
      <ToastContainer autoClose={1000} />
    </div>
  )
}

export default App
