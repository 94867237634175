import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function LicenseHistory() {
  const location = useLocation()
  const [info, setInfo] = useState(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    console.log(location.state)
    setInfo({
      ...(location.state.license?.license || {}),
      ...(location.state.license?.box || {}),
      purchaseHistory: [
        ...(location.state.license?.license?.purchaseHistory || []),
        ...(location.state.license?.box?.purchaseHistory || []),
      ],
    })
  }, [])

  return (
    <div className="container">
      <div style={{ marginBottom: 30 }}>
        <h3 style={{ textAlign: 'center' }}>Your Licenses</h3>
      </div>

      {info && (
        <div>
          <div className="row">
            <div className="col">
              <dl className="row">
                <dd className="col-sm-3">ERP Code</dd>
                <dt className="col-sm-9">{info['erpCode']}</dt>

                <dd className="col-sm-3">{info['valueType']?.toUpperCase()}</dd>
                <dt className="col-sm-9">{info['uniqueValue']}</dt>
              </dl>
            </div>
            <div className="col">
              <dl className="row">
                <dd className="col-sm-3">#Licenses</dd>
                <dt className="col-sm-9">{info?.['licenseCount'] || '0'}</dt>
              </dl>
              <dl className="row">
                <dd className="col-sm-3">#Boxes</dd>
                <dt className="col-sm-9">{info?.['boxCount'] || '0'}</dt>
              </dl>
            </div>
          </div>
          <hr />
          <h5>History</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Receipt</th>
              </tr>
            </thead>
            <tbody>
              {info['purchaseHistory'].map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item['purchaseDate']}</td>
                    <td>Rs.{item['amount'] / 100}</td>
                    <td>{item['paymentReceipt']}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      <Button onClick={() => navigate(-1)}>Back</Button>
    </div>
  )
}

export default LicenseHistory
