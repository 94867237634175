import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getLicenseInfo,
  searchGST,
  updateERPsByIds,
} from '../../services/client'

const ClientList = ({
  clients,
  onFindLicenseInfo,
  uniqueValue,
  uniqueType,
}) => {
  const navigate = useNavigate()
  const [info, setInfo] = useState([])
  const [open, setOpen] = useState(false)
  const [account, setAccount] = useState({})

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const groupClients = () => {
    const accounts = []
    for (const client of clients) {
      const erpAccounts = accounts.filter(
        (item) => item['erpCode'] == client['erpCode']
      )
      if (erpAccounts.length == 0) {
        accounts.push({
          erpCode: client['erpCode'],
          name: client['name'],
          address: client['address'],
          city: client?.['city'] || '',
          state: client?.['state'] || '',
          pinCode: client?.['pinCode'] || '',
          gst: client['gst'],
          pan: client['pan'],
          phone1: client['phone1'],
          users: [client],
        })
      } else {
        erpAccounts[0]['users'].push(client)
      }
    }
    setInfo(accounts)
  }

  useEffect(() => {
    groupClients()
  }, [])

  const onActivateLicense = (client) => {
    navigate('/activate-license', {
      state: {
        client,
        uniqueValue,
        uniqueType,
      },
    })
  }

  const onBoxPurchase = (client) => {
    navigate('/box-purchase', {
      state: {
        client,
        uniqueValue,
        uniqueType,
      },
    })
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            event.preventDefault()

            if (account.purchaseType === 'Purchase License') {
              const response = await updateERPsByIds({
                ...(({ users, ...rest }) => rest)(account),
                userIds: account.users.map((u) => u._id),
              })

              if (response.status === 'success') {
                onActivateLicense(account)
              }
            } else {
              const response = await updateERPsByIds({
                ...(({ users, ...rest }) => rest)(account),
                userIds: account.users.map((u) => u._id),
              })

              if (response.status === 'success') {
                onBoxPurchase(account)
              }
            }
          },
        }}
      >
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, gst: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="gst"
                name="gst"
                label="GST"
                type="text"
                value={account.gst}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, pan: e.target.value }))
                }
                autoFocus
                margin="dense"
                id="pan"
                name="pan"
                label="Pan No"
                type="text"
                value={account.pan}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, name: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                value={account.name}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, address: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                value={account.address}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, city: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="city"
                name="city"
                label="City"
                type="text"
                value={account.city}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, state: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="state"
                name="state"
                label="State"
                type="text"
                value={account.state}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, pinCode: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="pinCode"
                name="pinCode"
                label="Pin Code"
                type="text"
                value={account.pinCode}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setAccount((prev) => ({ ...prev, phone1: e.target.value }))
                }
                autoFocus
                required
                margin="dense"
                id="phone1"
                name="phone1"
                label="Mobile No"
                type="text"
                value={account.phone1}
                fullWidth
                variant="outlined"
              />
            </Grid>
            {/* Repeat the pattern for other fields */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Purchase
          </Button>
        </DialogActions>
      </Dialog>
      <table className="table table-striped">
        <tbody>
          <tr>
            <th>#</th>
            <th>ERP Code</th>
            <th>Account Name</th>
            <th>Address</th>
            <th># registered users</th>
            <th>Purchase</th>
          </tr>
        </tbody>
        <tbody>
          {info.map((account, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{account['erpCode']}</td>
                <td>{account['name']}</td>
                <td>{account['address']}</td>
                <td>{account['users'].length}</td>
                <td>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      // onActivateLicense(account)
                      setAccount({
                        ...account,
                        purchaseType: 'Purchase License',
                      })
                      handleClickOpen()
                    }}
                  >
                    License
                  </button>
                  <button
                    className="btn btn-sm btn-success"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      // onActivateLicense(account)
                      setAccount({ ...account, purchaseType: 'Purchase Box' })
                      handleClickOpen()
                    }}
                  >
                    Box
                  </button>
                  <button
                    className="btn btn-sm btn-warning"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      onFindLicenseInfo(account)
                    }}
                  >
                    History
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

function FindClient() {
  const [value, setValue] = useState('')
  const [type, setType] = useState(1)
  const [placeholder, setPlaceholder] = useState('GST Number')
  const [clients, setClients] = useState(undefined)
  const navigate = useNavigate()
  const logo = require('../../assets/logo.png')

  const onSearch = async () => {
    if (value.length == 0) {
      toast.warning('Please enter GST or PAN number')
    } else {
      const response = await searchGST(type, value)
      if (response['status'] == 'success') {
        setClients(response['data'])
      } else {
        toast.error(response['error'])
      }
    }
  }

  const onFindLicenseInfo = async () => {
    const info = await getLicenseInfo(value, type)
    if (info['status'] == 'success') {
      if (!info['data']['license'] && !info['data']['box']) {
        toast.error(
          'You do not have any active licenses. Click purchase button to continue with purchasing license(s).'
        )
      } else {
        navigate('/license-history', { state: { license: info['data'] } })
      }
    } else {
      toast.error(info['error'])
    }
  }

  return (
    <div className="container">
      <div className="row">
        <img src={logo} alt="" style={{ width: 200 }} />
      </div>
      <div className="fw-bold text-center">
        <div>
          License rate is <span className="fs-5">₹ 6000</span> per year per
          scanner
        </div>
        <div>
          Box rate is <span className="fs-5">₹ 1500</span>
        </div>
      </div>

      <div>
        <h3 style={{ textAlign: 'center', marginTop: 20 }}>
          Find your account
        </h3>
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FormControl style={{ marginTop: 10, minWidth: 100 }}>
          <InputLabel>Search Type</InputLabel>
          <Select
            value={type == '1' ? '1' : '2'}
            onChange={(e) => {
              setType(e.target.value)
              if (e.target.value == 1) {
                setPlaceholder('GST Number')
              } else {
                setPlaceholder('PAN Number')
              }
            }}
          >
            <MenuItem value={1}>GST</MenuItem>
            <MenuItem value={2}>PAN</MenuItem>
          </Select>
        </FormControl>
        <TextField
          style={{ marginLeft: 10 }}
          margin="normal"
          fullWidth
          label={placeholder}
          autoFocus
          onChange={(e) => {
            setValue(e.target.value)
          }}
          value={value}
        />
        <Button
          onClick={onSearch}
          variant="contained"
          color="success"
          size="medium"
          startIcon={<SearchIcon />}
          sx={{ marginLeft: 2, height: 50, minWidth: 120 }}
        >
          Search
        </Button>
      </Box>

      {clients && (
        <div className="" style={{ marginTop: 30 }}>
          {clients.length == 0 && (
            <h4>There are not accounts for {value}. Please try again.</h4>
          )}

          {clients.length > 0 && (
            <ClientList
              uniqueValue={value}
              uniqueType={type}
              onFindLicenseInfo={onFindLicenseInfo}
              clients={clients}
            />
          )}
        </div>
      )}

      <div className="fixed-bottom">
        <div className="container">
          <div
            style={{
              bottom: 0,
              height: 40,
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'space-between',
              justifyContent: 'space-between',
            }}
          >
            <a href="https://allscan.in/terms-conditions.html" target="_blank">
              Terms and Conditions
            </a>
            <a href="https://allscan.in/refunds.html" target="_blank">
              Cancellation Policy
            </a>
            <a href="https://allscan.in/privacy.html" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindClient
