import axios from 'axios'
import { createUrl } from '../common/utils'

export const searchGST = async (type, value) => {
  try {
    const body = {
      type: type == 1 ? 'gst' : 'pan',
      value,
    }
    const url = createUrl('erp/search')
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getLicenseCost = async () => {
  try {
    const url = createUrl('erp/license-cost')
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const confirmPayment = async (
  erp,
  uniqueValue,
  uniqueType,
  paymentReceipt,
  amount,
  licenseCount
) => {
  try {
    const body = {
      erp,
      uniqueValue,
      uniqueType,
      paymentReceipt,
      licenseCount,
      amount,
      discount: 0,
    }
    const url = createUrl('erp/payment-confirmation')
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const confirmPaymentBox = async (
  erp,
  uniqueValue,
  uniqueType,
  paymentReceipt,
  amount,
  boxCount
) => {
  try {
    const body = {
      erp,
      uniqueValue,
      uniqueType,
      paymentReceipt,
      boxCount,
      amount,
      discount: 0,
    }
    const url = createUrl('erp/payment-confirmation-box')
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getLicenseInfo = async (uniqueValue, uniqueType) => {
  try {
    const body = {
      uniqueValue,
      uniqueType,
    }
    const url = createUrl('erp/search-license')
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const updateERPsByIds = async (data) => {
  try {
    const url = createUrl('erp/update-erp')
    const response = await axios.post(url, { data })
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const postCreateOrder = async (order) => {
  try {
    const url = createUrl('erp/cashfree-payment')
    const response = await axios.post(url, { order })
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getCheckOrderStatus = async (orderId) => {
  try {
    const url = createUrl(`erp/cashfree-check-status?order_id=${orderId}`)
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}
