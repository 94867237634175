import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LicenseStepper from '../Stepper'

function ActivateLicense({}) {
  const location = useLocation()
  const [client, setClient] = useState(undefined)
  const navigate = useNavigate()
  const [uniqueType, setUniqueType] = useState('')
  const [uniqueValue, setUniqueValue] = useState('')

  useEffect(() => {
    setClient(location?.state?.client)
    setUniqueType(location?.state?.uniqueType)
    setUniqueValue(location?.state?.uniqueValue)
  }, [])

  const onLicenseCountChange = (count) => {}

  return (
    <div className="container">
      <div>
        <h3 style={{ textAlign: 'center' }}>Purchase AllScan license</h3>
      </div>
      <hr />
      {client && (
        <div>
          <div className="row">
            <div className="col">
              <dl className="row">
                <dd className="col-sm-3">Name</dd>
                <dt className="col-sm-9">{client['name']}</dt>

                <dd className="col-sm-3">Address</dd>
                <dt className="col-sm-9">{client['address']}</dt>
              </dl>
            </div>
            <div className="col">
              <dl className="row">
                <dd className="col-sm-3">GST Number</dd>
                <dt className="col-sm-9">{client['users'][0]['gst']}</dt>

                <dd className="col-sm-3">PAN Number</dd>
                <dt className="col-sm-9">{client['users'][0]['pan']}</dt>
              </dl>
            </div>
          </div>
          <hr />
          <h5>
            License will be applicable for ERP {'  '}
            <span style={{ fontSize: '1.5em' }} className="text-danger">
              {client['erpCode']}
            </span>
          </h5>
          <p>
            The licenses are applied to only selected ERP. Once purchased you
            wont be able to change ERP. Please confirm that you want to purchase
            the licenses for the selected ERP.
          </p>
          <div className="row">
            <div className="col">
              <LicenseStepper
                onValueChange={onLicenseCountChange}
                client={client}
                erpCode={client['']}
                uniqueType={uniqueType}
                uniqueValue={uniqueValue}
              />
            </div>
          </div>

          <hr />
          <Button onClick={() => navigate(-1)}>Back</Button>
        </div>
      )}
    </div>
  )
}

export default ActivateLicense
