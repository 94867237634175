import { Link } from 'react-router-dom'

function Terms() {
  return (
    <div className='container'>
      <h1 style={{ textAlign: 'center' }}>
        <strong>Terms &amp; Conditions</strong>
      </h1>

      <p>
        THESE TERMS OF SERVICE (the "Terms”) governs how you (“User”) may use
        the Platform provided by Modaviti eMarketing Private Limited
        (“Company”).
      </p>
      <p>
        The Super Enhance, allscan &amp; Orbo website and its subdomains located
        at superenhance.ai, www.allscan.in &amp; www.orbo.ai is a copyrighted
        work belonging to Modaviti eMarketing Private Limited. Certain features
        of the Site may be subject to additional guidelines, terms, or rules,
        which will be posted on the Site in connection with such features.
      </p>

      <p>
        All such additional terms, guidelines, and rules are incorporated by
        reference into these Terms.
      </p>

      <p>
        These Terms of Use described the legally binding terms and conditions
        that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE
        BEING COMPLIANT THAT THESE TERMS and you represent that you have the
        authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST
        18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE
        PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
      </p>

      <h3>
        <strong>Access to the Site</strong>
      </h3>

      <p>
        <strong>Subject to these Terms.</strong> Company grants you a
        non-transferable, non-exclusive, revocable, limited license to access
        the Site solely for your own personal, non-commercial use.
      </p>

      <p>
        <strong>Certain Restrictions.</strong> The rights approved to you in
        these Terms are subject to the following restrictions: (a) you shall not
        sell, rent, lease, transfer, assign, distribute, host, or otherwise
        commercially exploit the Site; (b) you shall not change, make derivative
        works of, disassemble, reverse compile or reverse engineer any part of
        the Site; (c) you shall not access the Site in order to build a similar
        or competitive website; and (d) except as expressly stated herein, no
        part of the Site may be copied, reproduced, distributed, republished,
        downloaded, displayed, posted or transmitted in any form or by any means
        unless otherwise indicated, any future release, update, or other
        addition to functionality of the Site shall be subject to these Terms. 
        All copyright and other proprietary notices on the Site must be retained
        on all copies thereof.
      </p>

      <p>
        Company reserves the right to change, suspend, or cease the Site with or
        without notice to you.  You approved that the Company will not be held
        liable to you or any third-party for any change, interruption, or
        termination of the Site or any part.
      </p>

      <p>
        <strong>Mailing list</strong> When signing up on Orbo.ai or
        superenhance.ai or allscan.in you are automatically subscribing to our
        mailing list. You can unsubscribe from the list at any time.
      </p>

      <p>
        <strong>No Support or Maintenance.</strong> You agree that the Company
        will have no obligation to provide you with any support in connection
        with the Site.
      </p>

      <p>
        Excluding any User Content that you may provide, you are aware that all
        the intellectual property rights, including copyrights, patents,
        trademarks, and trade secrets, in the Site and its content are owned by
        Company or Company’s suppliers. Note that these Terms and access to the
        Site do not give you any rights, title or interest in or to any
        intellectual property rights. Company and its suppliers reserve all
        rights not granted in these Terms.
      </p>

      <p>
        <strong>User Content.</strong> &quot;User Content&quot; means any and
        all information and content that a user submits to the Site. You are
        exclusively responsible for your User Content. You bear all risks
        associated with the use of your User Content.  You hereby certify that
        your User Content does not violate our Acceptable Use Policy.  You may
        not represent or imply to others that your User Content is in any way
        provided, sponsored or endorsed by the Company. Because you alone are
        responsible for your User Content, you may expose yourself to liability.
        Company is not obliged to backup any User Content that you post; You are
        solely responsible for making your own backup copies of your User
        Content if you desire.
      </p>

      <p>
        You hereby grant to Company an irreversible, nonexclusive, royalty-free
        and fully paid, worldwide license to reproduce, distribute, publicly
        display and perform, prepare derivative works of, incorporate into other
        works, and otherwise use and exploit your User Content, and to grant
        sublicenses of the foregoing rights, solely for the purposes of
        including your User Content in the Site.  You hereby irreversibly waive
        any claims and assertions of moral rights or attribution with respect to
        your User Content.
      </p>

      <p>
        <strong>Acceptable Use Policy.</strong> The following terms constitute
        our &quot;Acceptable Use Policy&quot;: You agree not to use the Site to
        collect, upload, transmit, display, or distribute any User Content (i)
        that violates any third-party right or any intellectual property or
        proprietary right; (ii) that is unlawful, harassing, abusive, tortious,
        threatening, harmful, invasive of another’s privacy, vulgar, defamatory,
        false, intentionally misleading, trade libellous, pornographic, obscene,
        patently offensive, promotes racism, bigotry, hatred, or physical harm
        of any kind against any group or individual; (iii) that is harmful to
        minors in any way; or (iv) that is in violation of any law, regulation,
        or obligations or restrictions imposed by any third party.
      </p>

      <p>
        In addition, you agree not to: (i) upload, transmit, or distribute to or
        through the Site any software intended to damage or alter a computer
        system or data; (ii) send through the Site unsolicited or unauthorized
        advertising, promotional materials, junk mail, spam, chain letters,
        pyramid schemes, or any other form of duplicative or unsolicited
        messages; (iii) use the Site to harvest, collect, gather or assemble
        information or data regarding other users without their consent; (iv)
        interfere with, disrupt, or create an undue burden on servers or
        networks connected to the Site, or violate the regulations, policies or
        procedures of such networks; (v) attempt to gain unauthorized access to
        the Site, whether through password mining or any other means; (vi)
        harass or interfere with any other user’s use and enjoyment of the Site;
        or (vi) use software or automated agents or scripts to produce multiple
        accounts on the Site, or to generate automated searches, requests, or
        queries to the Site.
      </p>

      <p>
        We reserve the right to review any User Content, and to investigate
        and/or take appropriate action against you in our sole discretion if you
        violate the Acceptable Use Policy or any other provision of these Terms
        or otherwise create liability for us or any other person. Such action
        may include removing or modifying your User Content, terminating your
        Account, and/or reporting you to law enforcement authorities.
      </p>

      <p>
        If you provide Company with any feedback or suggestions regarding the
        Site, you hereby assign to Company all rights in such Feedback and agree
        that Company shall have the right to use and fully exploit such Feedback
        and related information in any manner it believes appropriate.  Company
        will treat any Feedback you provide to Company as non-confidential and
        non- proprietary.
      </p>

      <p>
        <strong>Indemnification</strong> You agree to indemnify and hold Company
        and its officers, employees, and agents harmless, including costs and
        attorneys’ fees, from any claim or demand made by any third-party due to
        or arising out of (a) your use of the Site, (b) your violation of these
        Terms, (c) your violation of applicable laws or regulations or (d) your
        User Content.  Company reserves the right to assume the exclusive
        defence and control of any matter for which you are required to
        indemnify us, and you agree to cooperate with our defence of these
        claims.  You agree not to settle any matter without the prior written
        consent of the Company.  Company will use reasonable efforts to notify
        you of any such claim, action or proceeding upon becoming aware of it.
      </p>

      <h3> Fees and Charges</h3>
      <p>
        {' '}
        We reserves the right, at any time, to charge fees for accessing whole
        or part of the Sites. If, at any time, the user is required to pay fees
        for accessing any part of the Sites, the user will be intimated about
        the same and the user will be allowed to access such part of the Site
        only upon payment of “Subscription Fee” for a particular period
        specified at the time of subscription “Subscription Period”. The
        Subscription Fee is non-refundable and during the Subscription Period,
        the user is not required to pay any other charge towards subscription,
        unless there is a change in applicable tax. Subscription Fee can be paid
        in any of the modes listed on the Sites from time to time. The payment
        of Subscription Fee does not guarantee the subscription. Orbo reserves
        the right to reject request for subscription without assigning any
        reason. Under such circumstances, the Subscription Fee will be repaid
        without any interest.
      </p>
      <p>
        We reserves the right to revise the Subscription Fee from time to time.
        We , at its sole discretion, may allow the user to renew the
        subscription. After the expiry of the Subscription Period, upon payment
        of Subscription Fee prevalent at the time of renewal.
      </p>
      <h3>Disclaimers</h3>

      <p>
        The site is provided on an &quot;as-is&quot; and &quot;as
        available&quot; basis, and company and our suppliers expressly disclaim
        any and all warranties and conditions of any kind, whether express,
        implied, or statutory, including all warranties or conditions of
        merchantability, fitness for a particular purpose, title, quiet
        enjoyment, accuracy, or non-infringement.  We and our suppliers make no
        guarantee that the site will meet your requirements, will be available
        on an uninterrupted, timely, secure, or error-free basis, or will be
        accurate, reliable, free of viruses or other harmful code, complete,
        legal, or safe.
      </p>

      <h3>Limitation on Liability</h3>

      <p>
        To the maximum extent permitted by law, in no event shall company or our
        suppliers be liable to you or any third-party for any lost profits, lost
        data, costs of procurement of substitute products, or any indirect,
        consequential, exemplary, incidental, special or punitive damages
        arising from or relating to these terms or your use of, or incapability
        to use the site even if company has been advised of the possibility of
        such damages.  Access to and use of the site is at your own discretion
        and risk, and you will be solely responsible for any damage to your
        device or computer system, or loss of data resulting therefrom.
      </p>

      <p>
        You agree that our suppliers will have no liability of any kind arising
        from or relating to this agreement.
      </p>

      <p>
        <strong>General Representation and Warranty</strong> You represent and
        warrant that (i) your use of the Services will be in strict accordance
        with our Privacy Policy, with these Terms and with all applicable laws
        and regulations (including without limitation any local laws or
        regulations in your country, state, city, or other governmental area,
        regarding online conduct and acceptable content, and including all
        applicable laws regarding the transmission of technical data exported
        from the country in which you reside) and (ii) your use of the Website
        or Hosted Services will not infringe or misappropriate the intellectual
        property rights of any third party.
      </p>

      <p>
        <strong>Term and Termination.</strong> Subject to this Section, these
        Terms will remain in full force and effect while you use the Site.  We
        may suspend or terminate your rights to use the Site at any time for any
        reason at our sole discretion, including for any use of the Site in
        violation of these Terms.  Upon termination of your rights under these
        Terms, your Account and right to access and use the Site will terminate
        immediately.  You understand that any termination of your Account may
        involve deletion of your User Content associated with your Account from
        our live databases.  Company will not have any liability whatsoever to
        you for any termination of your rights under these Terms.
      </p>

      <h3>Copyright Policy</h3>

      <p>
        Company respects the intellectual property of others and asks that users
        of our Site do the same.  In connection with our Site, we have adopted
        and implemented a policy respecting copyright law that provides for the
        removal of any infringing materials and for the termination of users of
        our online Site who are repeat infringers of intellectual property
        rights, including copyrights.  If you believe that one of our users is,
        through the use of our Site, unlawfully infringing the copyright(s) in a
        work, and wish to have the allegedly infringing material removed, the
        following information in the form of a written notification must be
        provided to our designated Copyright Agent:
      </p>

      <p>● your physical or electronic signature;</p>
      <p>
        ● identification of the copyrighted work(s) that you claim to have been
        infringed;
      </p>
      <p>
        ● identification of the material on our services that you claim is
        infringing and that you request us to remove;
      </p>
      <p>● sufficient information to permit us to locate such material;</p>
      <p>● your address, telephone number, and e-mail address;</p>
      <p>
        ● a statement that you have a good faith belief that use of the
        objectionable material is not authorized by the copyright owner, its
        agent, or under the law; and
      </p>
      <p>
        ● a statement that the information in the notification is accurate, and
        under penalty of perjury, that you are either the owner of the copyright
        that has allegedly been infringed or that you are authorized to act on
        behalf of the copyright owner.
      </p>

      <p>
        Please note that, any misrepresentation of material fact in a written
        notification automatically subjects the complaining party to liability
        for any damages, costs and attorney’s fees incurred by us in connection
        with the written notification and allegation of copyright infringement.
      </p>

      <p>
        <strong>Confidentiality.</strong> All aspects of the arbitration
        proceeding shall be strictly confidential.  The parties agree to
        maintain confidentiality unless otherwise required by law.  This
        paragraph shall not prevent a party from submitting to a court of law
        any information necessary to enforce this Agreement, to enforce an
        arbitration award, or to seek injunctive or equitable relief.
      </p>

      <p>
        <strong>Jurisdiction and applicable law</strong>
      </p>

      <p>
        This Agreement is governed by the laws of India. The Indian courts will
        have non-exclusive jurisdiction over any claim arising from, or related
        to, a visit to our site although we retain the right to bring
        proceedings against you for breach of these conditions in your country
        of residence or any other relevant country. These terms of use and any
        dispute or claim arising out of or in connection with them or their
        subject matter or formation (including non-contractual disputes or
        claims) shall be governed by and construed in accordance with the law of
        India.
      </p>

      <p>
        <strong>Variations</strong>
      </p>

      <p>
        We may revise these terms of use at any time by amending this page. You
        are expected to check this page from time to time to take notice of any
        changes we made, as they are binding on you. Some of the provisions
        contained in these terms of use may also be superseded by provisions or
        notices published elsewhere on our site.
      </p>

      <p>
        <strong>Your Privacy.</strong> The information provided may be limited
        to the personal information we collect about you will be stored within
        our database for 12 months.
      </p>

      <p>
        <strong>Trademark Information.</strong> Copyright ©. All rights
        reserved.  All trademarks, logos and service marks displayed on the Site
        are our property or the property of other third-parties. You are not
        permitted to use these Marks without our prior written consent or the
        consent of such third party which may own the Marks.
      </p>

      <p>
        <strong>Contact us</strong> at support@orbo.ai
      </p>

      <Link to='/'>back</Link>
    </div>
  )
}

export default Terms
