function CancellationPolicy() {
  return (
    <div className='container'>
      <h1 style={{ textAlign: 'center' }}>
        <strong>Fees and Charges</strong>
      </h1>

      <p>
        We reserves the right, at any time, to charge fees for accessing whole
        or part of the Sites. If, at any time, the user is required to pay fees
        for accessing any part of the Sites, the user will be intimated about
        the same and the user will be allowed to access such part of the Site
        only upon payment of “Subscription Fee” for a particular period
        specified at the time of subscription “Subscription Period”. The
        Subscription Fee is non-refundable and during the Subscription Period,
        the user is not required to pay any other charge towards subscription,
        unless there is a change in applicable tax. Subscription Fee can be paid
        in any of the modes listed on the Sites from time to time. The payment
        of Subscription Fee does not guarantee the subscription. Allscan and
        Orbo reserves the right to reject request for subscription without
        assigning any reason. Under such circumstances, the Subscription Fee
        will be repaid without any interest.
      </p>
      <p>
        We reserves the right to revise the Subscription Fee from time to time.
        We , at its sole discretion, may allow the user to renew the
        subscription. After the expiry of the Subscription Period, upon payment
        of Subscription Fee prevalent at the time of renewal.
      </p>
    </div>
  )
}

export default CancellationPolicy
