import { Box, Button, ButtonGroup } from '@mui/material'
import { useEffect, useState } from 'react'
// import useRazorpay from 'react-razorpay'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  confirmPayment,
  getCheckOrderStatus,
  postCreateOrder,
} from '../../services/client'
import { cashfree } from '../../common/cashfree'

function LicenseStepper({ client, onValueChange, uniqueValue, uniqueType }) {
  const [count, setCount] = useState(1)
  const [price, setPrice] = useState(0)
  const [perLicenseCost, setPerLicenseCost] = useState(12000)

  // const Razorpay = useRazorpay()
  const navigate = useNavigate()

  const loadLicenseCost = async () => {
    // const response = await getLicenseCost()
    // if (response['status'] == 'success') {
    //   setPerLicenseCost(response['data'])
    // } else {
    //   toast.error('Error while getting license cost, please try again')
    //   navigate(-1)
    // }
    setPerLicenseCost(6000)
  }

  // const handlePayment = async (price) => {
  //   const amount =
  //     (perLicenseCost * count + (perLicenseCost * count * 18) / 100) * 100
  //   console.log(`total amount = ${amount}`)
  //   console.log(
  //     `value: ${uniqueValue}, type : ${uniqueType}, code: ${client['erpCode']}`
  //   )
  //   const options = {
  //     // key: 'rzp_test_d7iWauu3Vg1yer',
  //     key: 'rzp_live_u82EUY2IiR1VkC',
  //     amount: String(amount),
  //     currency: 'INR',
  //     handler: async (res) => {
  //       console.log('payment successful: ', res)

  //       const paymentReceipt = res['razorpay_payment_id']
  //       const response = await confirmPayment(
  //         client['erpCode'],
  //         uniqueValue,
  //         uniqueType,
  //         paymentReceipt,
  //         amount,
  //         count
  //       )
  //       console.log(response)
  //       if (response['status'] === 'success') {
  //         toast.success('Successfully made the payment')
  //         navigate('/')
  //       } else {
  //         toast.error(response['error'])
  //       }
  //     },
  //     prefill: {
  //       name: client['name'],
  //       address: client['address'],
  //       email: client['email1'],
  //       phone: client['phone1'],
  //       erpCode: client['erpCode'],
  //     },
  //     theme: {
  //       color: '#00695C',
  //     },
  //   }

  //   const rzpay = new Razorpay(options)
  //   rzpay.open()
  // }

  function generateOrderId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let order_id = ''

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      order_id += characters.charAt(randomIndex)
    }

    return order_id
  }

  const createOrder = async () => {
    const amount = perLicenseCost * count + (perLicenseCost * count * 18) / 100
    const generatedOrderId = generateOrderId()
    const response = await postCreateOrder({
      order_id: generatedOrderId,
      order_amount: amount,
      order_currency: 'INR',
      customer_details: {
        customer_id: uniqueValue, // GST or PAN as a customer_id
        customer_email: client['email1'],
        customer_phone: client['phone1'],
        customer_name: client['name'].replace(/[^\w\s]/gi, ''),
      },
      order_meta: {
        payment_methods: 'cc,dc,nb,upi',
      },
    })

    return response
  }

  const handleCashfreePayment = async () => {
    const response = await createOrder()
    let checkoutOptions = {
      paymentSessionId: response.payment_session_id,
      redirectTarget: '_modal',
    }

    cashfree()
      .then((result) => {
        result.checkout(checkoutOptions).then(() => {
          getCheckOrderStatus(response.order_id)
            .then(async (data) => {
              if (data.order_status === 'PAID') {
                const response = await confirmPayment(
                  client['erpCode'],
                  uniqueValue,
                  uniqueType,
                  data.order_id,
                  data.order_amount * 100,
                  count
                )
                if (response['status'] === 'success') {
                  toast.success('Successfully made the payment')
                  navigate('/')
                } else {
                  toast.error(response['error'])
                }
              }
            })
            .catch((err) => console.log('err', err))
        })
      })
      .catch((error) => {
        // Handle error
        console.error('Error loading Cashfree:', error)
      })
  }

  useEffect(() => {
    loadLicenseCost()
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div>
          # License: <strong>{count}</strong> x{' '}
          <strong>{perLicenseCost}</strong> ={' '}
          <strong>{count * perLicenseCost}</strong>
        </div>
        <ButtonGroup
          style={{ marginLeft: 10, marginRight: 10 }}
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => {
              setCount(count + 1)
            }}
          >
            +
          </Button>
          <Button
            onClick={() => {
              if (count > 0) {
                setCount(count - 1)
              }
            }}
          >
            -
          </Button>
        </ButtonGroup>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        <div>
          GST (18%) = <strong>{(count * perLicenseCost * 18) / 100}</strong>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        <div>
          Total = Rs{' '}
          <strong>
            {count * perLicenseCost + (count * perLicenseCost * 18) / 100}
          </strong>
        </div>
      </Box>

      <Button
        style={{ marginTop: 20 }}
        variant="contained"
        color="success"
        // onClick={() => handlePayment()}
        onClick={handleCashfreePayment}
      >
        Confirm Purchase
      </Button>
    </Box>
  )
}

export default LicenseStepper
