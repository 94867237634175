import { load } from '@cashfreepayments/cashfree-js'

export const cashfree = () => {
  return new Promise((resolve, reject) => {
    load({ mode: 'production' }) // or 'sandbox'
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
